/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import { Container } from 'reactstrap';

export default function Obsazenost() {
  useEffect(() => {
    window.scrollTo({ top: 500 });
  }, []);

  return (
    <>
      <div
        css={css`
          padding: 50px 0;
          background-color: #262523;
          min-height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <h2>Obsazenost</h2>
      </div>
      <Container
        css={css`
          padding: 50px 0;
          color: black;
          text-align: center;
        `}
      >
        <div>
          <script src='https://obsazenost.e-chalupy.cz/resize.js'></script>
          <iframe
            title='kalendar echalupy'
            src='https://obsazenost.e-chalupy.cz/kalendar.php?id=11823&legenda=ano&jednotky=ano&pocetMesicu=12&velikost=2'
            height='500px'
            width='100%'
            frameBorder='0'
          ></iframe>
        </div>
      </Container>
    </>
  );
}
