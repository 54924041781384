/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { Container } from 'reactstrap';

const Podminky = () => {
  useEffect(() => {
    window.scrollTo({ top: 500 });
  }, []);

  return (
    <>
      <div
        css={css`
          padding: 50px 0;
          background-color: #262523;
          min-height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        `}
      >
        <h2>Všeobecné podmíky - Chatička u Lesa</h2>
      </div>
      <Container
        id='test'
        css={css`
          padding: 50px 0;
        `}
      >
        <div
          css={css`
            text-align: center;
            color: black;
            div {
              margin: 25px 0;
            }
            @media screen and (max-width: 792px) {
              padding: 0 10px;
              font-size: 18px;
              div {
                margin: 10px 0;
              }
              h2,
              h3 {
                font-size: 25px;
                text-align: center;
              }
            }
          `}
        >
          <div>
            <h3>Provozovatel:</h3>
            <div>
              <br /> Radim Chudo (Jana Chudo)
              <br /> Nad Dvorem 35
              <br /> 713 00 Ostrava 13
            </div>
            <div>
              <br /> Email: <a href='mailto:chatickaulesa@gmail.com'>chatickaulesa@gmail.com</a>
              <br /> Tel.: +420 736139875
              <br /> (dále jen provozovatel)
            </div>
            <br /> <h4>Popis:</h4> Jedná se o užívání rekreační chaty “Chatička u lesa” v obci Frýdlant nad Ostravicí, Janovice -
            Bystré
            <h4>Adresa:</h4>
            <div>
              Lubno ev.č. 0145.
              <br /> (dále jen "Chatička u lesa").
            </div>
          </div>
          <div>
            <h2>Vznik smluvního vztahu - objednávka</h2>
            <div>
              Na základě souhlasu se Všeobecnými podmínkami chaty při rezervaci ubytování provozovatel poskytne ubytovanému
              (hostovi) přechodně ubytování (právo užívat prostor vyhrazený mu k ubytování, jakož i další ubytovací prostory u
              chaty) na sjednanou dobu a za předem stanovenou cenu. Ubytovaný (host) si objednává pobyt písemnou formou
              (e-mailem), nebo vyplněním a odesláním elektronického rezervačního formuláře na webových stránkách telefonicky nebo
              osobně.
            </div>
          </div>
          <div>
            <h3>Podstatné náležitosti pro řádnou rezervaci jsou:</h3>
            <div>
              jméno a příjmení ubytovaného (hosta), kontakt: email, telefon.
              <br />
            </div>
            <div>
              Rezervace (objednávka) se stává závaznou okamžikem jejího elektronického potvrzení provozovatelem na emailovou
              adresu ubytovaného (hosta) a vzniká mezi provozovatelem a ubytovaným (hostem) smluvní vztah.
            </div>
          </div>
          <div>
            <h3>Jak to u nás funguje</h3>
            <div>
              Maximální kapacita chaty je 6 osob. Kapacita chaty nesmí být bez vědomí provozovatele překročena. Hosté nejsou
              oprávněni vodit do chaty osoby neubytované.
            </div>
            <div>
              <b>Příjezd:</b> od 15 hod. do 19 hod.
              <br />
              <b>Odjezd:</b> do 10 hod.
              <br />
            </div>
            <div>
              Příjezd a odjezd lze měnit, pouze po předchozí dohodě s provozovatelem (zástupcem).
              <br /> Vzhledem k tomu, že chata nedisponuje stálou recepcí je potřeba, abyste nám sdělili svůj předpokládaný čas
              příjezdu a odjezdu.
            </div>
          </div>
          <br />
          <div>
            <b>
              Kontakt:
              <br /> +420 736139875 Jana Chudo
            </b>
          </div>
          <div>
            <h3>Vratná kauce:</h3>
            <div>
              ubytovaný host je povinen provozovateli zaplatit před nástupem do chaty částku ve výši 3.000,- Kč v hotovosti,
              oproti předání klíčů od objektu. Tato kauce bude v případě bezeškodného pobytu v hotovosti vrácena ubytovanému při
              převzetí chaty zpět provozovateli (zástupci).
            </div>
          </div>
          <div
            css={css`
              font-size: 25px;
              font-weight: bold;
            `}
          >
            Ztráta klíčů je za poplatek 1000,- Kč.
          </div>
          <div>
            Veškeré služby (nezahrnuté v ceně ubytování), které si host u provozovatele objedná je host povinen zaplatit hotově
            při předání nemovitosti nebo při předání samotné služby. Při pobytu ubytovaný host přebírá chatu včetně příslušenství
            bez závad. Případné závady je povinen neprodleně nahlásit provozovateli nebo jeho zástupci. Při nástupu na pobyt
            předloží hosté doklady totožnosti.
          </div>
          <div>
            <h3>Psi:</h3>
            <div>
              v chatě je možné ubytovat i pejsky po předchozí domluvě. Poplatek za psa je 200,- Kč/den. Ostatní zvířata, jsou
              zakázána.
            </div>
          </div>
          <div>
            <h3> Místní poplatek:</h3>
            <div>
              v ceně ubytování není zahrnut místní poplatek. Výše místního poplatku je 20 Kč/osoba/noc. Místní poplatek ubytovaný
              (host) zaplatí při předání chaty hotově provozovateli (zástupci)
            </div>
          </div>
          <div>
            <h3>Storno podmínky</h3>
            <b>storno pobytu 59 - 30 dní před začátkem pobytu účtujeme poplatek ve výši 50% z ceny ubytování</b>
            <br />
            <b>storno pobytu 29 - 14 dní před začátkem pobytu účtujeme poplatek ve výši 70% z ceny ubytování</b>
            <br />
            <b> storno pobytu 13 - 0 dní před začátkem pobytu účtujeme poplatek ve výši 100% z ceny ubytování</b>
            <br />
            <div>
              V případě výjimečných situací může provozovatel od storno poplatku či nároku na náhradu škody zcela či částečně
              ustoupit, rozhodnout o tomto přísluší výhradně provozovateli.
            </div>
            <br />
            <div>
              Ze závažných důvodů může provozovatel souhlasit se změnou rezervovaného termínu bez nároku na zaplacení storno
              poplatku, o tomto rozhoduje provozovatel.
            </div>
            <div>
              Výše storno poplatku je vypočítána vždy z celkové ceny rezervovaných ubytovacích služeb a dalších služeb objednaných
              objednatelem (hostem), a to včetně příplatků.
            </div>
            <div>
              V případě předčasného ukončení ubytování ze strany objednatele (hosta) bez zavinění provozovatele, není provozovatel
              povinen vrátit objednateli (hostovi) zaplacenou cenu ubytování ani z části. Objednatel (host) bere na vědomí, že
              takto nevrácená část kupní ceny za předčasně zrušené ubytovací služby ze strany objednatele (hosta) představuje
              nárok provozovatele na náhradu škody dle § 2330 odst. 2 občanského zákoníku.
            </div>
            <div>
              Rezervovanou ubytovací službou či rezervovaným ubytováním se rozumí veškeré objednatelem (hostem) objednané
              ubytování a další služby nabízené provozovatelem v rozsahu jím provedené závazné rezervace a objednávky.
            </div>
          </div>
          <div>
            <h3>DODRŽUJTE</h3>
            <div>
              Hosté jsou povinni udržovat a zanechat chatu, v čistém a původním stavu. Pro udržení domácího prostředí prosíme u
              vchodu do chaty o přezouvání.
            </div>
            <div>
              Do toalety nevhazujte hygienické potřeby. Je zakázáno odhazovat odpadky mimo určené místo (odpadkový koš), nebo
              jinak znečišťovat prostory chaty. Kontejner na odpad je umístěn mimo objekt chaty a je nutné odvést odpad osobním
              vozidlem ke kontejneru.
            </div>
            <div>
              Není dovoleno přemisťovat nábytek, vybavení, provádět jakékoliv úpravy a zasahovat do elektrické sítě a jiných
              instalací. Nábytek z chaty též není možné přesouvat na terasu.
            </div>
          </div>
          <div>
            <h3>Před odjezdem - předání chaty:</h3>
            <div>
              V předem dohodnutý čas host osobně předá provozovateli (zástupci) klíče od chaty a provozovatel (zástupce) provede
              zběžnou kontrolu.
            </div>
            <div>
              Před odjezdem je host povinen vypnout všechna zařízení chaty, vyklidit lednici a umýt nádobí (vyklidit/zapnout
              myčku).
            </div>
            <div>
              Nadprůměrné znečištění není v ceně standardního úklidu např. bláto na podlaze, špinavá lednice, neumyté nádobí
              (nezapnutá/nevyklizená myčka), špinavé zdi, nepořádek a odpadky kolem chaty apod. V případě neuvedení do pořádku,
              bude účtována smluvní pokuta ve výši 2.000,- Kč a uhrazeno v hotovosti před odjezdem provozovateli (zástupci).
            </div>
            <div>
              Rozbité vybavení – účtujeme pouze pořizovací ceny. V případě poškození pokojů nebo jiných prostor chaty nebo při
              ztrátě klíčů je provozovatel oprávněn po hostovi požadovat odpovídající finanční náhradu.
            </div>
          </div>
          <div>
            <h3>Bezpečnost </h3>
            <div>
              Provozovatel seznámí nájemce s venkovním grilem a krbem. Při odchodu z chaty je host odpovědný za zkontrolování
              elektrických spotřebičů (vypnutí elektrické trouby, varné desky), vypnutí světel, zavření všech oken. Uzamčení
              chaty. Host dbá zvýšené opatrnosti při zacházení s ohněm a dbá všech bezpečnostních pokynů a obecných nařízení.
              Otevřený oheň je možný pouze ve venkovním ohništi, který je k tomuto určen. Při odchodu ohniště vždy uhaste.
            </div>
            <div>
              Při používání venkovního grilu postupujte vždy dle přiloženého návodu, který najdete uvnitř chaty. Na interiérovém
              schodišti je třeba dávat pozor, schodnice kloužou, hrozí nebezpečí poranění, stejně tak dbejte zvýšené opatrnosti na
              terase a zahradě.
            </div>
            <div>
              Povinností hosta je oznámit neprodleně změny či nedostatky, které nastanou v průběhu pobytu. V chatě je pod hrozbou
              okamžitého ukončení pobytu zakázáno kouřit uvnitř chaty, užívat drogy či jiné psychotropní látky. (bez nároku na
              jakoukoliv kompenzaci)
            </div>
            <div>
              Každý přítomný je povinen neprodleně ohlásit nebezpečí vzniku požáru, situace vyžadující zásah policie nebo lékařské
              ošetření a v rámci svých možností učinit vše, aby zabránil šíření požáru či odvrátil hrozící nebezpečí.
            </div>
            <div>
              Provozovatel nenese odpovědnost za úrazy či škody na majetku způsobené nedostatečným odklizením sněhu okolo objektu
              nebo pádem sněhu ze střechy.
            </div>
            <div>
              V ceně pobytu není obsaženo pojištění. Za úrazy a poškození nebo odcizení majetku a odpovědnost za škody způsobené
              na zdraví a majetku třetí osobě provozovatel neodpovídá.
            </div>
            <div>Host musí mít k dispozici v době pobytu nabitý telefon pro případ potřeby.</div>
            <div>
              Nenechávejte děti bez dozoru v objektu ani v jeho bezprostředním okolí. Za jejich bezpečnost a jimi způsobené škody
              odpovídají vždy zákonní zástupci nebo osoba, která dítě doprovází.
            </div>
          </div>
          <div>
            <h3>Zpracování osobních údajů</h3>
            <div>
              Chatička u lesa zpracovává osobní údaje registrovaných subjektů, které byly poskytnuty Chatičce u lesa v rámci
              registrace při rezervaci v souladu s platnými zákony České republiky, zejména se zákonem o ochraně osobních údajů č.
              101/2000 Sb. ve znění pozdějších předpisů. Osobní údaje jsou shromažďovány prostřednictvím webových stránek
            </div>
            <div>
              Mezi osobní údaje patří údaje poskytnuté při telefonické a e-mailové rezervaci a při nákupu dárkových poukazů,
              kterými jsou: jméno a příjmení, e-mailová adresa, fakturační adresa, doručovací adresa a telefonní číslo.
            </div>
            <div>
              Zákazník (host) rezervací na stránkách chatickaulesa.cz souhlasí se zpracováním a shromažďováním svých osobních
              údajů v databázi a ty dále nejsou poskytovány žádným dalším stranám.
            </div>
            <div>Poskytování osobních údajů je dobrovolné; bez něj však nelze úspěšně dokončit rezervaci.</div>
            <div>Registrovaný subjekt (host) může kdykoliv zažádat o vymazání veškerých osobních údajů z databáze.</div>
            <div>
              Registrovaný subjekt (host) má právo kdykoliv odvolat souhlas se shromažďováním a zpracováním osobních údajů také
              prostřednictvím e-mailové adresy doplnit!!!
            </div>
            <div>Po odvolání souhlasu dojde k vymazání veškerých osobních údajů z databáze.</div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Podminky;
