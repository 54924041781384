/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import { Container } from 'reactstrap';

export default function Hodnoceni() {
  useEffect(() => {
    window.scrollTo({ top: 500 });

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML =
      "window.echalupyIdObjektu = '11823'; window.echalupyVzhled = 'p'; window.celkoveHodnoceni = '0'; window.pocetZaznamu = 'vse';";
    document.body.appendChild(s);

    const script = document.createElement('script');

    script.src = 'https://api2.e-chalupy.cz/konfigurator/hodnoceni/widget/dist/widgetHodnoceni.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div
        css={css`
          padding: 50px 0;
          background-color: #262523;
          min-height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <h2>Hodnocení</h2>
      </div>
      <Container
        css={css`
          padding: 50px 0;
          color: black;
          text-align: center;
        `}
      >
        <div>
          <div id='echalupy-hodnoceni'></div>
        </div>
      </Container>
    </>
  );
}
